'use strict'

import { clone } from '../helpers/Global'
import Dataservice from './DataService'

const $user = {
  user: null,
  _resolvers: [],
  wasAuthed () {
    return this.user != null
  },
  isAuthorized () {
    return new Promise((resolve) => {
      if (this.user != null) {
        resolve(true)
      } else {
        Dataservice.get('me').then((user) => {
          this.user = user
          resolve(true)
        }).catch(() => {
          this.user = null
          resolve(false)
        })
      }
    })
  },
  refreshUser () {
    return new Promise((resolve) => {
      Dataservice.get('me').then((user) => {
        this.user = user
        resolve(true)
      }).catch(() => {
        this.user = null
        resolve(true)
      })
    })
  },
  isAdmin () {
    return this.user.admin === true || false
  },
  get () {
    return clone(this.user)
  }
}

export default $user
